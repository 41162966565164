import { useNavigate, Link, useLocation } from "react-router-dom";
import { AiOutlineInfoCircle, AiOutlineProfile } from "react-icons/ai";
import { BsFillTrash3Fill } from "react-icons/bs";
import { displayDate } from "../utilities/functions";
import { FaFile, FaLink } from "react-icons/fa";
import DropdownBenSearch from "./DropdownSearchBen";
import { GetSharedStreamContent, GetStreamContent, GetVisitLabelByToken } from "../utilities/calls";
import { useState } from "react";
import QRCodeModal from "./QRCodeModal";


export default function StreamBox({ stream, token, individualID, beneficiaryID, onDelete, ben, message, onConnect, sharedTo, benList, connected, serviceProviderId, benId, sentBadge, from, to, created, updated, requestId, isBusiness, visitToken }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [visitLink, setVisitLink] = useState();
    const [qrcode, setQrcode] = useState();
    const handleOpenModal = () => {
        if (visitLink) {
            setQrcode(visitLink);
            setModalOpen(true);
        }
    }
    const handleCloseModal = () => setModalOpen(false);
    const viewFileClicked = () => {
        setShowConfirmationBox(true);
    }

    const handleGetVisitLink = (e) => {

        GetVisitLabelByToken(visitToken).then((resp) => {
            let visitlink = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/#/visitlink/' + resp;
            setVisitLink(visitlink);
            navigator.clipboard.writeText(visitlink);
        }
        ).catch((err) => console.log('err', err));
    }

    const handleViewFile = () => {
        setShowConfirmationBox(false)

        if (token) {
            GetSharedStreamContent(stream.ID, token, beneficiaryID, individualID).then(resp => {
                console.log(resp)
                const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
                const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
                // Create a temporary link element to download the file
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }).catch(err => {
                console.log('Error in downloading content', err);
            })
        } else {
            GetStreamContent(stream.ID).then(resp => {
                const blob = new Blob([resp.data], { type: resp.headers['Content-Type'] });
                const filename = stream?.tags?.__SYS_JW_FileName?.Value ? stream?.tags?.__SYS_JW_FileName?.Value : 'downloaded-file';
                // Create a temporary link element to download the file
                const downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }).catch(err => {
                console.log('Error in downloading content', err);
            })
        }
    }

    const fetchServiceProviderDetails = (serviceProviderID) => {
        if (serviceProviderID !== null && serviceProviderID !== undefined) {
            navigate(`/AddServiceProviderProfile/${serviceProviderID}`, { state: { readOnly: true, showPrivate: false, previousPage: pathname } })
        }
    }
    const fetchBenDetails = (benId) => {
        if (benId !== null && benId !== undefined) {
            navigate(`/AddBeneficiaryProfile/${benId}`, { state: { readOnly: true, showPrivate: false, previousPage: pathname } })
        }
    }

    const fetchBeneficiaryDetails = (benId) => {
        console.log("The Ben Id is : ", benId, benList)
        if (benId !== null && benId !== undefined) {
            navigate(`/AddBeneficiaryProfile/${benId}`, { state: { readOnly: true, showPrivate: true, previousPage: pathname } })
        }
    }

    return (
        stream ?
            <div className="relative md-container border border-gray-500 bg-gray-800 rounded m-1 pt-6 pb-12 px-3" style={{ "overflow-wrap": "break-word" }}>
                <div className="flex flex-row justify-between">
                    <p className="font-bold leading-6 text-white flex flex-row justify-around">
                        <Link class="font-semibold cursor-pointer" to={`/documentdetails/${stream.ID}`} state={{ "readonly": true, "previousPage": pathname, "stream": stream, beneficiaryID: beneficiaryID, token: token, individualID: individualID }} >
                            {stream?.tags?.Name?.Value}</Link>
                    </p>
                    <p>
                        {onDelete ?
                            <button formMethod="dialog" formTarget="top" id="deleterequest" className="bg-slate-333 py-1 hover:bg-555" onClick={onDelete}>
                                <BsFillTrash3Fill title="Delete" className="inline-icon" />
                            </button>
                            : <></>}
                    </p>
                </div>
                <p><span class="text-xs font-medium me-2 text-blue-300 "><AiOutlineProfile className='inline-icon' style={{ height: "1rem", width: "1rem" }} />Document</span>          </p>
                <p>{sentBadge ? <span class="text-xs text-center px-1 py-0.5 rounded-full bg-gray-700 text-green-400 border border-green-400">Document Sent</span> : <></>}
                </p>
                <p className="mt-1 text-xs text-gray-400">{updated ? "Updated " + displayDate(updated) : ""}  </p>
                {updated && created && updated !== created ? <p className="text-xs text-gray-400">{created ? "Created " + displayDate(created) : ""}</p> : <></>}
                <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                    {(stream?.Description || "")}
                </p>
                <p className="mt-2 truncate text-sm leading-5 text-gray-100">
                    {stream?.tags?.__SYS_JW_FileName?.Value ?
                        <button formMethod="dialog" formTarget="top" id="download" onClick={() => viewFileClicked()} className="text-blue-400 text-xs bg-slate-333 hover:bg-555 hover:underline py-1">
                            <FaFile className="inline-icon" style={{ height: "1rem", width: "1rem" }} /> {stream?.tags?.__SYS_JW_FileName?.Value}
                        </button>
                        : <></>}

                </p>

                {from && from !== "" ?
                    <p className="mt-2 leading-6 text-gray-100">
                        <span className="text-xs ">Shared By: </span>
                        <span className="text-xs  text-blue-400">{from}</span>
                    </p> : <></>
                }
                {to && to !== "" ? <>
                    <p className=" leading-6 text-gray-100">
                        <span className="text-xs">Shared With: </span>
                        <span className="text-xs text-blue-400">{to}</span>
                    </p>{visitToken ? <>
                        <button formMethod="dialog" formTarget="top" id="visitLink" className="mt-1 items-center inline-flex justify-center px-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-blue-500" onClick={handleGetVisitLink}>
                            <FaLink title="Get Visit Link" className="inline-icon" />Create Share Link and QR Code</button>
                        <p class="mt-1 italic text-gray-200 text-xs">Link is valid for 24 hours.</p>
                        <div>
                            {visitLink && (
                                <>
                                    <span class="text-sm text-green-400"> Copied to clipboard!</span>
                                    <button className="mt-1 items-center inline-flex justify-center px-2 py-1.5 text-xs font-medium text-center rounded-lg text-white bg-blue-500" onClick={handleOpenModal}>Show QR Code</button>
                                    {isModalOpen && (
                                        <QRCodeModal
                                            isOpen={isModalOpen}
                                            onClose={handleCloseModal}
                                            qrValue={qrcode}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </>
                        : <></>}
                </> : <></>
                }
                {sharedTo && sharedTo.length > 0 ?
                    <p className=" leading-6 text-gray-100">
                        <span className="text-xs">Shared With: </span>
                        {
                            ben ? (<button className="text-xs text-blue-400" onClick={() => fetchServiceProviderDetails(serviceProviderId)}>{sharedTo}</button>)
                                : (<button className="text-xs text-blue-400" onClick={() => fetchBenDetails(benId)}>{sharedTo}</button>)
                        }
                    </p> : <></>
                }
                {
                    requestId && ben.length > 0 ? (<div class="w-full mt-2 p-4 rounded-lg shadow-sm border bg-gray-800 border-gray-600 text-blue-300" role="alert">
                        <div class="flex">
                            <p class=" text-sm">Your recommendation was accepted. <span class="text-blue-400">{ben.map((b) => { return <button onClick={() => fetchBeneficiaryDetails(b.beneficiaryID)}>{"  " + benList.find(bn => bn.id === b.beneficiaryID)?.tags?.name?.Value + " "}</button> })} (Beneficiary)</span> is now a connection!</p></div></div>
                    ) : (requestId ? <DropdownBenSearch requestId={requestId} benList={benList} /> : <></>)
                }
                {
                    !isBusiness && ben ?
                        ben.map((id) => {

                            return <div class="w-full mt-2 p-4 rounded-lg shadow-sm border bg-gray-800 border-gray-600 text-blue-300" role="alert">
                                <div class="flex">
                                    {connected ? <div class="py-2 text-sm "> <span className="font-semibold ">Great! </span><div>You are connected with {benList[id]?.tags?.name?.Value + " (beneficiary)"} recommended by {sharedTo + " (Service Provider)"}.</div></div>
                                        :
                                        <div class="ms-1 text-sm font-normal">
                                            <span class="mb-1 text-sm font-semibold text-white"><AiOutlineInfoCircle className="inline-icon" /> Recommendation! </span>
                                            <div class="py-2 text-xs">{sharedTo} has referred {benList[id]?.tags?.name?.Value} to you.</div>
                                            <blockquote class="text-xs italic font-semibold text-white">
                                                <p>{message && message.length > 0 ? '"' + message + '"' : <></>}</p>
                                            </blockquote>
                                            <div class="grid grid-cols-2 gap-2 p-2">
                                                <div>
                                                    <Link to={`/AddBeneficiaryProfile/${id}`} state={{ readOnly: true, showPrivate: false, previousPage: pathname }} >
                                                        <button class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-800">View</button></Link>
                                                </div>
                                                <div>
                                                    <button onClick={onConnect} class="inline-flex justify-center w-full px-2 py-1.5 text-xs font-medium text-center rounded-lg focus:ring-4 focus:outline-none text-white bg-blue-500 hover:bg-blue-600 focus:ring-blue-800">Connect</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>


                        }) : <></>
                }
                {showConfirmationBox && <div className="fixed inset-0 !mt-0 grid place-items-center overflow-auto z-50 bg-black bg-opacity-50">

                    <div className='w-11/12 max-w-[500px] rounded-lg border border-gray-700 bg-gray-800 flex flex-col gap-2 relative'>

                        <button className="absolute top-4 right-1 text-lg font-normal text-gray-400  -translate-x-2 -translate-y-1" onClick={() => setShowConfirmationBox(false)}>X</button>


                        <div className='flex flex-col gap-5 items-center p-6 mx-4 text-base font-normal text-white'>
                            <p className='font-medium'>Download Document</p>

                            <p class="mb-4 text-gray-300">By continuing, you agree to not store, not use it for creating a database or not use it for long term.</p>
                            <div class="flex justify-center items-center space-x-4">
                                <button onClick={handleViewFile} type="submit" class="py-2 px-3 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none bg-blue-500 hover:bg-blue-600 focus:ring-blue-900">
                                    Continue
                                </button>
                                <button onClick={() => setShowConfirmationBox(false)} type="button" class="py-2 px-3 text-sm font-medium rounded-lg border focus:ring-4 focus:outline-none focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:text-white hover:bg-gray-600 focus:ring-gray-600">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
            : <></>);
}