// src/components/AddressDetails.js
import React, { useLayoutEffect, useState } from "react";
import { useParams, Link , useLocation} from "react-router-dom";
import { AiOutlineProfile } from "react-icons/ai";
import { address_locales, countryLocaleList } from "../utilities/templates";

import {
  GetRequestDetails,
  GetSecondaryTokenForRequest,
  GetAddress,
} from "../utilities/calls";

const ViewAddressDetailsForRequest = () => {
  const { requestid } = useParams();
  const [address, setAddress] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(
    Intl.NumberFormat().resolvedOptions().locale
  );
  const { state: { previousPage, tokens }} = useLocation();

  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();



  useLayoutEffect(() => {
    GetRequestDetails(requestid)
      .then((request) => {
        var ben = request.fromBeneficiaryID !== "" ? request.fromBeneficiaryID : (request.fromServiceProviderID !== "" ? request.fromServiceProviderID : "");
        if(request.fromBeneficiaryID !== "" || request.fromServiceProviderID === ""){
        GetSecondaryTokenForRequest(request.id)
          .then((response) => {

            GetAddress(
              request.addressID,
              ben,
              response.token,
              request.toIndividualID
            )
              .then((address) => {
                address.name = address.tags.atag.Name;
                setAddress(address);
                const countryVal = countryLocaleList.find((o) => o.name === address.country)?.value;
                setSelectedCountry(countryVal);   
              })
              .catch((error) => {
                console.error("Error fetching address details:", error);
                setAlertText("Error fetching address details, please try again!");
                setShowAlert(true)
              }
              );
          })
          .catch((error) => {
            console.error(
              `Error fetching details for token with request ID ${requestid}:`,
              error
            )
            setAlertText("Error fetching address details, please try again!");
            setShowAlert(true)
          }
          );
      } else {
        GetAddress(
          request.addressID,
          ben,
          tokens[request.addressID],
          request.toIndividualID
        )
          .then((address) => {
            address.name = address.tags.atag.Name;
            setAddress(address);
            const countryVal = countryLocaleList.find((o) => o.name === address.country)?.value;
            setSelectedCountry(countryVal);   
          })
          .catch((error) => {
            console.error("Error fetching address details:", error);
            setAlertText("Error fetching address details, please try again!");
            setShowAlert(true)
          }
          );
      }})
      .catch((error) => {
        console.error(
          `Error fetching request details for ID ${requestid}:`,
          error
        )
        setAlertText("Error fetching address details, please try again!");
                setShowAlert(true)
      }
      );
    // Define the API endpoint to fetch the address details (replace with your actual API endpoint)
  }, [requestid, tokens]);

  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
        <div>
          <h1 className="form-label py-2">
            <AiOutlineProfile className="inline-icon" />
            Address Details
          </h1>
        </div>

        <div>{address_locales[selectedCountry] ? address_locales[selectedCountry].map((element) => {return renderField(element.label,element.id,element.type,address[element.id],element.options);})
            : address_locales["default"].map((element) => {return renderField(element.label,element.id,element.type,address[element.id],element.options);})}
        </div>
        <section className="grid grid-cols-12 gap-20">
          <ul style={{ display: "flex", listStyleType: "none", padding: 0 }}>
            <li key="close">
              <Link to={previousPage ? previousPage : "/"}>
                <button className="button-style form-button">Close</button>
              </Link>
            </li>
          </ul>
          <div
            class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative"
            role="alert"
            style={{ display: showAlert ? "block" : "none" }}
          >
            <span class="text-sm">{alertText} </span>
          </div>
        </section>
      </div>
    </div>
  );
};

function renderField(label, id, type, val, onChange, options) {
  return (
    <div className="grid grid-cols-12">
      <label className="col-span-12 form-label" htmlFor={id}>
        {label}:
      </label>
      <input readOnly type="text" onChange={onChange} id={id} name={id} value={val} placeholder={label} className="col-span-12 px-2 py-2 form-input"/>
    </div>
  );
}

export default ViewAddressDetailsForRequest;
