import { useEffect, useState } from "react";
import { useSearchParams,useNavigate } from "react-router-dom";
import { SearchBeneficiaries, SearchSP } from "../utilities/calls";
import { AiOutlineGlobal, AiOutlineMail, AiOutlinePhone, AiOutlineMessage,AiOutlineQrcode  } from "react-icons/ai";
import { FaLink } from "react-icons/fa";
import { BsClipboard2Check } from "react-icons/bs";
import { serviceCategoriesList } from "../utilities/templates";
import { displayEmaillink, displayPhoneLink, displayWeblink, displayLogoURL } from "../utilities/functions";
import QRCodeModal from "./QRCodeModal";

export default function SearchList() {
    const [resultsSP, setResultsSP] = useState([]);
    const [resultsBen, setResultsBen] = useState([]);
    const searchParams = useSearchParams();
    const [filterState, setFilterState] = useState(0);
    const [qrcode, setQrcode] = useState();
    const [isQrModalOpen, setIsQrModalOpen] = useState(false);

    const navigate = useNavigate();


    const updateList = (search) => {
        SearchSP(search).then((resp) => { console.log("searchSP", resp); setResultsSP(resp) }).catch((err) => { console.log("Error search sp", err) })
        SearchBeneficiaries(search).then((resp)=> { console.log("searchBeneficiaries", resp); setResultsBen(resp) }).catch((err) => { console.log("Error search ben", err) })
    }

    const connect = (e) => {
        const s=searchParams.get("text");
        const button = e.target.closest("button");
        navigate("/contactServiceProvider/" + button.id,{ state: { text: s } })
    }
    const connectBen = (e) => {
        const s=searchParams.get("text");
        const button = e.target.closest("button");
        navigate("/contactServiceProvider/" + button.id,{ state: { text: s , isBen: true} })
    }
    const showQRCode = (sp) => {
        let linkForQR = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/home/index.html#/contactServiceProvider/' + sp.id;
        setQrcode(linkForQR);
        setIsQrModalOpen(true);
      };

    const copyShareLink = (sp, e) => {
        document.getElementById('c2cicon_' + sp.id).style.display = "";
        resultsSP.forEach((spl) => {
            if(spl.id !== sp.id){
                document.getElementById('c2cicon_' + spl.id).style.display = "none";
            }   
        })
        let shareLink = process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + '/home/index.html#/contactServiceProvider/' + sp.id;
        navigator.clipboard.writeText(shareLink); 
    } 

    useEffect(() => {
        const s = searchParams.get("text") || "";
        updateList(s);
        if(document.getElementById("search")){
            document.getElementById("search").value = s;
        }
    }, [searchParams]);
   
    const fetchSPDetails=(e,spId)=>{
        const s=searchParams.get("text");
        e.stopPropagation()
        if(spId !== null && spId!== undefined){
            navigate(`/AddServiceProviderProfile/${spId}`,{ state: { readOnly:true,showPrivate:true,previousPage: `/search/?text=${s}` } })
        }
    }

    const fetchBenDetails=(e,spId)=>{
        const s=searchParams.get("text");
        e.stopPropagation()
        if(spId !== null && spId!== undefined){
            navigate(`/AddBeneficiaryProfile/${spId}`,{ state: { readOnly:true,showPrivate:true,previousPage: `/search/?text=${s}` } })
        }
    }

    const filter = (state) => {
        setFilterState(state);
    }

    return <div className="px-4 py-3 max-w-screen-xl mx-auto items-center">
        <h1 className="p-5 bold-text-input">Search Results</h1>
        {isQrModalOpen && (
				        <QRCodeModal
				          isOpen={isQrModalOpen}
				          onClose={() => {setIsQrModalOpen(false)}}
				          qrValue={qrcode}
				        />
				      )}
        <div class="p-5 space-y-3 border border-gray-700 ">
        <span class={ filterState === 0 ? "cursor-pointer text-sm font-medium me-2 px-2.5 py-0.5 rounded bg-green-700 text-green-300 " :  "bg-gray-700 text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"} onClick={()=> filter(0)}>All</span>
        <span class={ filterState === 1 ? "cursor-pointer text-sm font-medium me-2 px-2.5 py-0.5 rounded bg-green-700 text-green-300" : "bg-gray-700 text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer"  } onClick={() => filter(1)}>Service Providers</span>
        <span class={ filterState === 2 ?  "cursor-pointer text-sm font-medium me-2 px-2.5 py-0.5 rounded bg-green-700 text-green-300" : "bg-gray-700 text-gray-300 text-sm font-medium me-2 px-2.5 py-0.5 rounded cursor-pointer" } onClick={()=> filter(2)}>Beneficiaries </span>
            <div class="w-full p-4 border rounded-lg shadow border-gray-700">
                {resultsSP.length === 0 && resultsBen.length === 0 ? <span>No results found. Please try using different keyword.</span> :
                    <ul class="w-full divide-y divide-gray-700">
                        {
                            filterState === 0 || filterState === 1 ? resultsSP?.map ((r, index) => {
                                return <li class="py-3 sm:py-4 " >
                                    
                                    <div className=" flex flex-row justify-start space-x-2">
                                        { displayLogoURL(r?.tags?.logourl?.Value)}
                                        <button onClick={(e)=>fetchSPDetails(e,r.id)} class="py-1 px-1 text-sm md:font-medium font-semibold text-left break-words">{r.tags?.name?.Value?.length > 0 ? r.tags?.name?.Value : "No name"} </button>
                                    </div>
                                    
                                    <span class="font-normal text-gray-100 text-xs italic"> Service Provider</span>
                                    
                                    
                                    <p className="mt-2 md:text-sm text-xs text-gray-400" id={"guid_" + index}>{r.id}</p>

                                    {r.tags?.categories?.Value?.length > 0 ? <p class="mt-2 text-gray-100">{serviceCategoriesList.find((c) => c.value === r.tags?.categories?.Value )?.name || "" }</p> : <></>}
                                    {r.tags?.description?.Value?.length > 0 ? <p class="mt-2 text-sm truncate text-gray-100">
                                        {r.tags?.description?.Value}
                                    </p> : <></>}
                                    {r.tags?.addressee?.Value?.length > 0 ? <p className="mt-2 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.addressee?.Value} {r.tags?.street?.Value}
                                    </p> : <></>}
                                    {r.tags?.city?.Value?.length > 0 || r.tags?.state?.Value?.length > 0 || r.tags?.zipcode?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.city?.Value}{r.tags?.city?.Value?.length > 0 ? "," : ""} {r.tags?.state?.Value} {r.tags?.zipcode?.Value}
                                    </p> : <></>}
                                    {r.tags?.country?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.country?.Value}
                                    </p> : <></>}
                                    {r.tags?.phone?.Value?.length > 0 ? <p className="mt-2 text-semibold leading-6 text-blue-400"><AiOutlinePhone color="white" className="inline-icon"/>{displayPhoneLink(r.tags?.phone?.Value)}</p> : <></>}
                                    {r.tags?.email?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineMail color="white" className="inline-icon"/>{displayEmaillink(r.tags?.email?.Value)}</p> : <></>}
                                    {r.tags?.website?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineGlobal color="white" className="inline-icon"/>{displayWeblink(r.tags?.website?.Value)}</p> : <></>}
                                    <div class="py-2">
                                    <button formMethod="dialog" formTarget="top" id={r.id} onClick={connect}
                                            className="button-style">
                                            <AiOutlineMessage title="Contact Service Provider" className="inline-icon " />
                                            Connect
                                        </button>
                                        <p className="py-2">
                                        <button onClick={(e) => copyShareLink(r, e)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline "><FaLink className="inline-icon"/>Copy Share Link <BsClipboard2Check  fill="green" style={{display: "none"}} id={"c2cicon_" + r.id  } className="inline-icon" title="Copied to clipboard"/></button>
                                        <button formMethod="dialog" formTarget="top" id={r.id} onClick={() => showQRCode(r)} className="text-blue-400 bg-slate-333 hover:bg-555 hover:underline py-1 md:px-2"> <AiOutlineQrcode className="inline-icon"/>Share with QRCode</button>
                                        </p>
                                        </div>
                                </li>
                            }) : <></>
                        }
                        {
                            filterState === 0 || filterState === 2 ? resultsBen?.map((r, index) => {
                                return <li class="py-3 sm:py-4 " >
                                    
                                    <div className=" flex flex-row justify-start space-x-2 flex-wrap ">
                                    <span className="flex-shrink-0"> {displayLogoURL(r?.tags?.logourl?.Value)}</span>
                                        <button onClick={(e)=>fetchBenDetails(e,r.id)} class="py-1 text-sm md:font-medium font-semibold text-left break-words">{r.tags?.name?.Value?.length > 0 ? r.tags?.name?.Value : "No name"} </button>
                                    </div>
                                    <span class="font-normal text-gray-100 text-xs italic"> Beneficiary</span>
                                    <p className="mt-2 md:text-sm text-xs text-gray-400" id={"guid_" + index}>{r.id}</p>

                                    {r.tags?.categories?.Value?.length > 0 ? <p class="mt-2 text-gray-100">{serviceCategoriesList.find((c) => c.value === r.tags?.categories?.Value )?.name || "" }</p> : <></>}
                                    {r.tags?.description?.Value?.length > 0 ? <p class="mt-2 text-sm truncate text-gray-100">
                                        {r.tags?.description?.Value}
                                    </p> : <></>}
                                    {r.tags?.addressee?.Value?.length > 0 ? <p className="mt-2 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.addressee?.Value} {r.tags?.street?.Value}
                                    </p> : <></>}
                                    {r.tags?.city?.Value?.length > 0 || r.tags?.state?.Value?.length > 0 || r.tags?.zipcode?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.city?.Value}{r.tags?.city?.Value?.length > 0 ? "," : ""} {r.tags?.state?.Value} {r.tags?.zipcode?.Value}
                                    </p> : <></>}
                                    {r.tags?.country?.Value?.length > 0 ? <p className="mt-1 truncate text-sm leading-5 text-gray-100">
                                        {r.tags?.country?.Value}
                                    </p> : <></>}
                                    {r.tags?.phone?.Value?.length > 0 ? <p className="mt-2 text-semibold leading-6 text-blue-400"><AiOutlinePhone color="white" className="inline-icon"/>{displayPhoneLink(r.tags?.phone?.Value)}</p> : <></>}
                                    {r.tags?.email?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineMail color="white" className="inline-icon"/>{displayEmaillink(r.tags?.email?.Value)}</p> : <></>}
                                    {r.tags?.website?.Value?.length > 0 ? <p className="text-semibold leading-6 text-blue-400"><AiOutlineGlobal color="white" className="inline-icon"/>{displayWeblink(r.tags?.website?.Value)}</p> : <></>}
                                    <div class="py-2">
                                    <button formMethod="dialog" formTarget="top" id={r.id} onClick={connectBen}
                                            className="button-style">
                                            <AiOutlineMessage title="Contact Service Provider" className="inline-icon" />
                                            Connect
                                        </button>
                                        </div>
                                </li>
                            }) : <></>
                        }
                    </ul>
                }
            </div>
        </div>
    </div>
}