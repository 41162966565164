// src/components/CreateAddressRequest.js
import React, { useEffect,useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CreateAddressRequestTo, getInfo,GetTemplateByID, GetTemplatesByServiceProviderID } from "../utilities/calls";
import { GetAllContactsWithEmailAddresses,SearchContacts} from "../utilities/email"
import { AiOutlineImport } from "react-icons/ai";
import FilteredList from "./FilteredList.js";

const RequestAddress = () => {
  const [requestsent, setRequestSent] = useState(false);
  const [request, createRequest] = useState({
    email: "",
    note: "",
  });
  const [showAlert, setShowAlert] = useState();
  const [alertText, setAlertText] = useState();
  const [emailListForSelect, setEmailListForSelect] = useState([]);
  const navigate = useNavigate();
  const [contenttype, setContenttype] = useState("Addresses");

  const [templates, setTemplates] = useState([{
    Name: "Documents", Tags: {
      "DisplayName": {
        "Name": "DisplayName",
        "Value": "Documents",
        "Private": false,
        "Required": true,
        "Editable": false
      }
    }
  }, {
    Name: "Addresses", Tags: {
      "DisplayName": {
        "Name": "DisplayName",
        "Value": "Addresses",
        "Private": false,
        "Required": true,
        "Editable": false
      }
    }
  }]);


  useEffect(() => {
    const redirect = sessionStorage.getItem('pg_redirect');
    if (redirect !== undefined && redirect !== null) {
      navigate(redirect);
    } else {
      let defaultSP = getInfo('DefaultServiceProvider');
      if (defaultSP) {
        GetTemplatesByServiceProviderID(defaultSP).then((templateIdsArr) => {
          templateIdsArr.forEach((id) => {
            GetTemplateByID(id).then((template) => {
              setTemplates((templates) => [...templates, template]);
            }).catch(err => console.log('Error fetching templates for spid ', defaultSP, err));
          })
        }).catch(err => console.log('Error fetching templates for spid ', defaultSP, err));
      }

      const gmailaccesstoken = getInfo("Gmailaccesstoken");
      if (gmailaccesstoken && gmailaccesstoken !== "") {
        getGoogleEmailContacts(gmailaccesstoken);
      } else {
        setGmailAuth();
        getGoogleEmailContacts();
      }
    }
  }, []);

 const setGmailAuth = () => {
           window.open(process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_HOST + process.env.REACT_APP_URL_API + "/gmail/authorization/call?&authtoken=" + sessionStorage.getItem('JWAUTH') , "_self");
   }
  const getGoogleEmailContacts = (gmailaccesstoken) => {
    gmailaccesstoken = gmailaccesstoken ? gmailaccesstoken : getInfo("Gmailaccesstoken")
    if (gmailaccesstoken && gmailaccesstoken !== "") {
      GetAllContactsWithEmailAddresses(gmailaccesstoken)
        .then((response) => {
          var localc = [];
          response.forEach((nameVal) => {
            const option = {
              value: nameVal.email,
              label: nameVal.name + "(" + nameVal.email + ")",
            };
            localc = [...localc, option];
          });  
          setEmailListForSelect(localc);   
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('gmail access token is not present');
    }
  }
  
  const processForm = () => {
    const individualid = getInfo("IndividualID");
    var selectedContent = document.getElementById('contentselect').value;
    var rtags = {
      "note": {
        Name: "Content Request",
        Value: request.note,
        Private: false,
        Required: false,
        Editable: false,
      },
      "contenttype": {
        Name: "contenttype",
        Value: selectedContent,
        Private: false,
        Required: false,
        Editable: false,
      }
    };
    var reqtype = "SHARE_ADDRESS";
    if (contenttype !== "Addresses") { 
      if (contenttype === "Documents") 
        reqtype = "SHARE_STREAM"; 
      else 
        reqtype = "SHARE_SECUREDCONTENT" 
    };
    CreateAddressRequestTo(individualid, request.email, rtags, reqtype)
      .then((response) => {
        console.log("done requesting address. received: ", response);
        setRequestSent(true);
        if (response["redirectTo"] !== null) {
          window.alert("Invite " + request.email);
          const link = response["redirectTo"];
          const a = document.createElement("a"); a.setAttribute('href', link); a.setAttribute('target', '_blank'); a.click(); 
        } else {
          setShowAlert(true);
          setAlertText("Request sent!");
        }
      })
      .catch((error) => {
        console.error("Error requesting address:", error);
        setShowAlert(true);
        setAlertText("Failed to send request!");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    createRequest((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEmailChange = (newValue) => {
    const gmailaccesstoken = getInfo("Gmailaccesstoken");
    if(gmailaccesstoken){
      SearchContacts(gmailaccesstoken,newValue).then((res)=>{
        setEmailListForSelect(res.contacts)
      })
    }
    const name = "email";
    createRequest((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
  };

  const updateContentType = (e) => {
    setContenttype(e.target.value);
  }

  return (
    <div style={{ marginTop: "20px", marginLeft: "20px" }}>
      <div className="mx-auto max-w-screen-lg form-container p-5">
          <h1 className="py-2 form-label">
            <AiOutlineImport className="inline-icon" />
            Request Content
          </h1>
          

        <article className="mt-10">
          <form id="form-request-new" name="form-request-new">
            <div className="grid grid-cols-12 gap-3 ">
              <label className="col-span-12 form-label" htmlFor="email">
                Request content from (Type or choose Email below) :
              </label>
              <FilteredList onChange={(newValue) => handleEmailChange(newValue)} initialListProp={emailListForSelect} className="col-span-12 form-input px-2 py-2"/>
              <label className="col-span-12 form-label" htmlFor="contentselect">
                       Choose content type :
                    </label>
                    <select id="contentselect" onChange={updateContentType} className="col-span-12 form-input px-2 py-2" >
                      {templates.sort((a, b) => { return (a?.Tags?.DisplayName?.Value || a?.Name)?.toLowerCase().localeCompare((b?.Tags?.DisplayName?.Value || b?.Name)?.toLowerCase()) }).map((ele, index) => { return <option value={ele?.Name} selected={index===0}> {ele?.Tags?.DisplayName?.Value || ele?.Name} </option> })}
                    </select>
              <label htmlFor="note" className="col-span-12 form-label">Send Note with the Request :</label>
              <input onChange={handleInputChange} type="text" id="note" name="note" placeholder="Request for content" className="col-span-12 form-input px-2 py-2"/>
            </div>
          </form>
        </article>
<section class="flex justify-start items-center space-x-4 py-2 ">
          <button style={{width: "fit-content",display: requestsent ? "none" : "block",}} formMethod="dialog" formTarget="top" type="submit" onClick={processForm} id="btn-request-add-submit" form="form-request-new" className="button-style positive-action">
            SEND
          </button>
          {!requestsent ? (
            <Link to="/requests" className="button-style">
              <button >Cancel</button>
            </Link>
          ) : (
            <Link to="/requests" className="button-style">
              <button>Close</button>
            </Link>
          )}
          <div class=" col-span-10 bg-blue-100 border-blue-500 text-blue-700 px-2 py-2 relative" role="alert" style={{ display: showAlert ? "block" : "none" }} >
            <span class="text-sm">{alertText} </span>
          </div>
        </section>
        
      </div>
    </div>
  );
};

export default RequestAddress;
